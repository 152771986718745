@import '../variables.scss';

#light {

  //gloabl search styles
  .global-search-container {
    position: relative !important;

    .MuiIconButton-root {
      padding: 0 !important;
    }
  }

  .global-search-items {
    position: absolute !important;
    top: 35px !important;

    // background: $mer-color4;
    background-color: #FFFFFF;
    min-width: 260px;
    max-height: 316px;
    overflow-y: auto;

    .global-search-item {
      // border-bottom: 1px solid $mer-color2;
      // color: $mer-color2;

      border-bottom: 1px solid $acc-content-primary;
      color: $acc-content-primary;

      .name {
        // color: $mer-color2;
        color: $acc-content-primary;
        font-weight: bold;
      }
    }
  }
  .mer-tabshadowmobile{
   box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12); 
  }
  .light-grayclr {
    color: #7b7a7a;
  }

  // .mer-styletheme-select {
  //   .MuiSelect-icon {
  //     color: white !important;
  //   }
  // }


  @for $i from -100 to 450 {

    .mer-styletheme-table-sticky-header-right-#{$i} {
      position: sticky;
      right: $i + px !important;
      z-index: 101 !important;
      // background: #E2DFFF !important;
    }



    .mer-styletheme-table-sticky-header-left-#{$i} {
      position: sticky;
      left: $i + px !important;
      z-index: 101 !important;
      // background: #E2DFFF !important;
    }

    .mer-styletheme-table-sticky-body-right-#{$i} {
      position: sticky;
      right: $i + px !important;
      z-index: 100 !important;
      background: $acc-container-primary;
      // background: linear-gradient(0deg, rgba(86, 85, 168, 0.05), rgba(86, 85, 168, 0.05)), #FFFBFF !important;
    }

    .mer-styletheme-table-sticky-body-right-#{$i}:hover {
      // background-color: #eeeef2 !important;
      background: $acc-container-secondary !important;
    }

    .mer-styletheme-table-sticky-body-left-#{$i} {
      position: sticky;
      left: $i + px !important;
      z-index: 100 !important;
      background: $acc-container-primary;
      // background: linear-gradient(0deg, rgba(86, 85, 168, 0.05), rgba(86, 85, 168, 0.05)), #FFFBFF !important;
    }
    
    .mer-styletheme-table-sticky-body-left-#{$i}:hover {
      background: $acc-container-secondary !important;
    }

    .MuiTableBody-root:hover {
      background-color: #eeeef2 !important;
    }
  }

  .mer-icon-color {
    color: $acc-content-primary;
  }
  .mer-color2{
    // color: $mer-color2;
    color: $acc-content-primary;
  }
  .mer-color6{
    color:$mer-color6;
  }
  // .sourced-profile-name-highlight {
  //   color: $mer-color2;
  //   // font-weight: bold;
  // }

  .sourced-profile-name-highlight:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  
  // .referral-profile-name-highlight {
  //   color: $mer-color2;
  //   // font-weight: bold;
  // }

  .mer-tab-color-selected {
    color: #1F242F;
    font-weight: 700;
    opacity: 1
  }

  .mer-tab-color-unselected {
    color: #1F242F;
    font-weight: 600;
    opacity: 1
  }

  .mer-access-denied-button{
    background-color: #ffffff;
    border-radius: 10px;
  }

  .mer-autoComplete {
    .MuiButtonBase-root {
      background-color: #FFFFFF !important;
    }
  }

  .mer-autoComplete > .MuiFormControl-root > .MuiOutlinedInput-root > .MuiChip-root {
    background-color:   rgb(217, 216, 216) !important;
  }

  .mer-light-purple-background {
    background-color: #dad1f3;
  }
}