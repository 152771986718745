

$mer-color1: #E2DFFF;
$mer-color2: #100563;
$mer-color3: #6750A4;
$mer-color4: #F2EFFF; 
$mer-color5:#fffbff;
$mer-color6:#95AF28;

$acc-container-primary:#F2F2F2; //gray-95
$acc-container-secondary:#E5E5E5; //gray-90
$acc-container-gray-80:#CCCCCC;
$acc-container-gray-70:#B2B2B2;
$acc-container-gray-60:#999999;
$acc-container-gray-50:#808080;
$acc-container-gray-40:#666666;
$acc-container-gray-30:#4D4D4D;
$acc-container-gray-20:#333333;
$acc-container-gray-10:#1A1A1A;

$acc-citron:#EEFB87; //citron-76
$acc-citron-80:#F1FC9C;
$acc-citron-90:#F8FDCE;
$acc-citron-95: #FCFEE6;
$acc-citron-99: #FEFFFA;

$acc-content-primary:#1F242F; //dark-gray
$acc-light-grey:#818995;
$acc-burlywood: #BF9856;
$acc-lavender: #EADCF5;
$acc-light-sea-green: #3BA184;
$acc-light-sky-blue:#91C2F2;
