// @import './utills/constants/themes.js';

@import url('https://fonts.googleapis.com/css?family=Poppins');

@import './variables.scss';


// .mer-color2 {
//   color: $mer-color2;
// }

// .mer-bg-color3 {
//   background-color: $mer-color3;
//   color: #fff;

//   .MuiSvgIcon-root-MuiSelect-icon.MuiSelect-icon {
//     color: #fff;
//   }
// }

.mer-error {
  color: #d32f2f;
  font-size: 12px !important;
  padding: 0 5px;
}

.mer-note {
  color: #d32f2f;
  font-size: 12px !important;
}


@font-face {
  font-family: 'Messina Sans';
  src: url('./assets/fonts/MessinaSans-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('./assets/fonts/MessinaSans-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('./assets/fonts/MessinaSans-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Messina Sans Mono';
  src: url('./assets/fonts/MessinaSansMono-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-variant: small-caps;
}

* {
  font-size: 14px !important;
  font-family: 'Messina Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
  /* font-family: 'Futura PT'; */
}

// * {
//   font-size: 14px !important;
//   // font-family: 'Poppins' !important; 
//   font-family: 'Futura PT' !important;
// }

// .mer-loginlabel {
//   color: #100563;
//   font-size: 22px !important;

// }


// Drawer styles

// .MuiDrawer-paper{
//   top: 65px !important;
// }

html,
body,
#root {
  min-height: 100%;
  height: 100%;
}

.mer-fill {
  min-height: 100%;
  height: 100%;
}

.iconclr {
  color: $acc-content-primary;
}



@media only screen and (max-width: 600px) {
  .mer-flexdirection {
    flex-direction: column;
    align-items: center;
  }

  .mer-flexdirection-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

@media only screen and (min-width: 600px) {
  .mer-flexdirection {
    flex-direction: row;
    align-items: center;

  }

  .mer-flexdirection-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

.mer-cursor-pointer {
  cursor: pointer;
}


.mer-text-align-center {
  text-align: center;
}

.mer-font-access-denied {
  color: #410002;
}

.mer-access-denied-card {
  background-color: #FFDAD6;
}

.mer-cursor-default {
  cursor: default;
}

@media only screen and (max-width: 600px) {
  .designationtbl {
    margin-left: 70px;
    width: 77%
  }
}

@media only screen and (min-width: 600px) {
  .designationtbl {
    width: 100%
  }
}

@for $i from 1 to 1500 {
  .mer-z-index-#{$i} {
    z-index: $i !important;
  }

  .mer-font-weight-#{$i} {
    font-weight: $i !important;
  }
}

@for $i from 0 to 101 {
  .mer-width-percentage-#{$i} {
    width: #{$i}#{"%"} !important;
  }

  .mer-height-percentage-#{$i} {
    height: #{$i}#{"%"} !important;
  }

  .mer-max-width-percentage-#{$i} {
    width: #{$i}#{"%"} !important;
  }
}



@for $i from -100 to 450 {

  // .mer-table-sticky-header-right-#{$i}{
  //   position: sticky;
  //   right:  $i + px !important;
  //   z-index: 100 !important;
  //   background: #E2DFFF !important;
  // }

  // .mer-table-sticky-header-left-#{$i}{
  //   position: sticky;
  //   left:  $i + px !important;
  //   z-index: 100 !important;
  //   background: #E2DFFF !important;
  // }

  // .mer-table-sticky-body-right-#{$i}{
  //   position: sticky;
  //   right:  $i + px !important;
  //   z-index: 100 !important;
  //   background: linear-gradient(0deg, rgba(86, 85, 168, 0.05), rgba(86, 85, 168, 0.05)),#FFFBFF !important;
  // }

  // .mer-table-sticky-body-left-#{$i}{
  //   position: sticky;
  //   left:  $i + px !important;
  //   z-index: 100 !important;
  //   background: linear-gradient(0deg, rgba(86, 85, 168, 0.05), rgba(86, 85, 168, 0.05)), #FFFBFF !important;

  // }

  .mer-margin-#{$i} {
    margin: $i + px !important;
  }

  .mer-margin-right-#{$i} {
    margin-right: $i + px !important;
  }

  .mer-margin-left-#{$i} {
    margin-left: $i + px !important;
  }

  .mer-margin-top-#{$i} {
    margin-top: $i + px !important;
  }

  .mer-margin-bottom-#{$i} {
    margin-bottom: $i + px !important;
  }

  .mer-padding-#{$i} {
    padding: $i + px !important;
  }

  .mer-padding-x-#{$i} {
    padding-left: $i + px !important;
    padding-right: $i + px !important;
  }

  .mer-padding-y-#{$i} {
    padding-top: $i + px !important;
    padding-bottom: $i + px !important;
  }

  .mer-margin-x-#{$i} {
    margin-left: $i + px !important;
    margin-right: $i + px !important;
  }

  .mer-margin-y-#{$i} {
    margin-top: $i + px !important;
    margin-bottom: $i + px !important;
  }


  .mer-padding-right-#{$i} {
    padding-right: $i + px !important;
  }

  .mer-padding-left-#{$i} {
    padding-left: $i + px !important;
  }

  .mer-padding-top-#{$i} {
    padding-top: $i + px !important;
  }

  .mer-padding-bottom-#{$i} {
    padding-bottom: $i + px !important;
  }

  .mer-font-size-#{$i} {
    font-size: $i + px !important;
  }

  .mer-min-width-#{$i} {
    min-width: $i + px !important;
  }

  .mer-min-height-#{$i} {
    min-height: $i + px !important;
  }

  .mer-max-width-#{$i} {
    max-width: $i + px !important;
  }

  .mer-max-height-#{$i} {
    max-height: $i + px !important;
  }

  .mer-width-#{$i} {
    width: $i + px !important;
  }

  .mer-border-radius-#{$i} {
    border-radius: $i + px !important;
  }

  .mer-height-#{$i} {
    height: $i + px !important;
  }

  .mer-line-height-#{$i} {
    line-height: $i + px !important;
  }

  .mer-rounded-#{$i} {
    border-radius: $i + px !important;
  }

  .mer-border-width-#{$i} {
    border-width: $i + px !important;
  }

  @media screen and (max-width: 576px) {
    .mer-mobile-width-percentage-#{$i} {
      width: #{$i}#{"%"} !important;
    }

    .mer-mobile-margin-right-#{$i} {
      margin-right: #{$i}+px !important;
    }

    .mer-mobile-margin-left-#{$i} {
      margin-left: #{$i}+px !important;
    }

    .mer-mobile-padding-right-#{$i} {
      padding-right: #{$i}+px !important;
    }

    .mer-mobile-margin-bottom-#{$i} {
      margin-bottom: #{$i}+px !important;
    }
  }
}

#root {
  .select-sub-text-hide {
    display: none !important;
  }
}

.mer-login-background {
  background-image: linear-gradient(to top right, #95af28, white, #3a3087)
}

.mer-solid-border {
  border-style: solid;
}



.MuiSvgIcon-root {
  font-size: 2.5rem !important;
  cursor: pointer;
}

// .form-field {
//   min-width: 300px !important;
// }

.selectdisabled {
  color: $acc-container-gray-70;
}

.mer-object-fit {
  object-fit: contain;
}

// .mer-table>tbody>tr:nth-child(odd) {
//   // background-color: #f7f7f7;
//   background-color: white;
// }

.mer-overflow {
  overflow: auto !important;
}

.mer-overflow-hidden {
  overflow: hidden !important;
}

// .mer-button {
//   text-transform: none !important;
//   padding: 6px 16px !important;
//   border-radius: 4px !important;
// }

// .mer-button-primary {
//   color: #fff !important;
//   background-color: #1565c0 !important;
// }

// .mer-button-outlined {
//   border: 1px solid rgba(25, 118, 210, 0.5) !important;
//   color: #1976d2 !important;
// }

// .mer-button-text {
//   color: #1976d2 !important;
// }

// .mer-checkbox {
//   .MuiSvgIcon-root {
//     width: 1.5rem !important;
//     height: 1.5rem !important;
//   }

//   .MuiButtonBase-root-MuiCheckbox-root {
//     padding: 0 0 0 9px !important;
//   }

//   margin-left: 0px !important;
//   // margin-top: 9px !important;
// }

// .mer-select {
// .MuiButtonBase-root-MuiMenuItem-root {
//   padding-left: 16px !important;
// }

// .MuiButtonBase-root {
//   display: flex !important;
//   align-items: center !important;
//   justify-content: flex-start !important;
// }

// }

.mer-privacy-checkbox {
  height: auto !important;
}

.mer-menu-item {
  padding: 0;
  height: 30px;
  margin-left: 0;
}

.mer-menu-subgroup-check-box {
  transform: scale(0.6) !important;
  margin-right: -10px !important;
}

.mer-menu-select-check-box {
  transform: scale(0.6) !important;
  margin-right: -10px !important;
  margin-left: -16px !important;
}

.MuiTablePagination-displayedRows {
  padding-top: 16px !important;
}

// .MuiButtonBase-root-MuiIconButton-root {
//   float: left;
// }

// .MuiInputBase-root-MuiTablePagination-select {
//   padding-right: 20px !important;
//   margin-right: 15px !important;
// }

.MuiTablePagination-selectLabel {
  padding-top: 14px !important;
}

// .confirm-action-dialog-box {
//   .MuiDialogActions-root {
//     justify-content: center !important;
//   }
// }


.loader-style {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  background-color: white;
  opacity: 0.6;
}

.MuiRadio-root>span>svg {
  width: 25px;
}

.MuiFormControlLabel-root {
  height: 40px;
}

.MuiRadio-root {
  height: 40px
}


.form-fieldset {
  // margin: 10px;
  margin-top: 16px;
  padding: 0 16px 16px 16px;
  border-radius: 5px;
  border: 1px solid black !important;
}

.form-legend {
  font-size: 16px !important;
  background-color: #fff;
  position: relative;
  width: max-content !important;
  padding: 0 10px !important;
  top: -15px;
  left: 15px;
}

.mer-form-legend-with-close {
  font-size: 16px !important;
  background-color: #fff;
  position: relative;
  width: 98% !important;
  padding: 0 10px !important;
  top: -13px;
  left: 15px;
}

.mer-color-red {
  color: #d32f2f
}

.mer-datepicker {
  .MuiSvgIcon-root {
    width: 24px !important;
    height: 24px !important;
  }
}

// .MuiClockPicker-root{
//   background: #F2EFFF !important;
//   .MuiIconButton-root{
//     color: white !important;
//   }

//   #E2DFFF
// }

.mer-purple-color {
  // color: $mer-color3;
  color: $acc-content-primary;
}

// .mer-skill-item {
//   padding: 8px;
//   // margin: 5px;
//   // border: 1px solid grey;
//   border-radius: 8px;
//   // padding-right: 15px;
//   // background-color: white;
//   width: max-content !important;
// }



// .dialog-box {
//   .MuiPaper-root-MuiDialog-paper {
//     width: 50% !important;
//     min-height: 60% !important;
//     max-height: auto !important;
//   }
// }

.upload-field {
  border: 1px dashed black;
  // margin: 24px;
  padding: 16px;
  border-radius: 8px;
}

.search-class {
  .MuiSvgIcon-root {
    font-size: 1.5rem !important;
    cursor: pointer;
  }
}

.mer-stepper-button {
  justify-content: center !important;
}

.mer-bg-grey {
  background-color: #D9D9D9;
  color: #000;
  padding: 16px;
  margin-bottom: 10px;
}

.mer-font-bold {
  font-weight: 600 !important;
}

.mer-field-bg-white {
  color: black !important;
  background-color: #fff !important;
  border-radius: 4px !important;
}

.mer-field-bg-white>* {
  color: black !important;
}

.RichTextEditor_container {
  background: transparent !important;
}

.mer-overflow-x-hidden {
  overflow-x: hidden !important;
}

.mer-overflow-y-auto {
  overflow-y: auto !important;
}

.mer-card {
  background-color: #e7e0ec;
  border-radius: 8px;
  margin: 12px
}

.mer-white-card {
  background-color: white;
  padding: 12px;
  border-radius: 8px;
}

.mer-rating-card {
  background-color: #FFFBFE;
}

hr {
  margin: 8px 0 !important;
}

.mer-card-shadow {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

// Side menu styles


.mer-toggle-icon {
  background: #ffffff;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  color: rgb(107, 119, 140);

  position: fixed;
  top: 100px;
  width: 25px;
  height: 25px;
  z-index: 1101 !important;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  transition: background-color 100ms linear 0s, color 100ms linear 0s, opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

.mer-login-icon-background {
  background: $mer-color5 !important;
}

.mer-skel-view {
  margin: 0px 12px 12px 0px;
  padding: 12px;
  border-radius: 8px;

}

.mer-skel-table {
  margin: 0px 12px 3px 0px;
  padding: 11px;

}

.mer-skel-sidemenu {
  margin: 2px 0px 2px 0px;
  border-radius: 100px;
}

.mer-skel-search {
  margin: 0px 12px 3px 0px;
  padding: 11px;
  border-radius: 100px;
}



.mer-toggle-position-opened {
  left: 185px;
}

.mer-toggle-position-closed {
  left: 8px;
}


.mer-sidemenu-item {
  padding: 8px 16px;
  cursor: pointer;
  border-bottom: 1px solid #dddd;
  text-decoration: none;
  overflow-y: auto;
}

.mer-sidemenu-subitem>div {
  padding: 8px 16px 8px 32px;
  cursor: pointer;
  border-bottom: 1px solid #dddd;
  text-decoration: none;
}

.mer-sidemenu-subitem {
  text-decoration: none;
}

.mer-bg-gray {
  background-color: #e7e0ec;
  border-radius: 6px;
  padding: 4px
}


.mer-link-without-underline {
  text-decoration: none;
}

.mer-text-underline {
  text-decoration: underline;
}

.mer-text-decoration-none {
  text-decoration: none;
}

.mer-stepper-label>.MuiStepLabel-labelContainer>.MuiStepLabel-alternativeLabel {
  margin-top: 0px !important;
}

.mer-bg-white {
  background-color: white;
}

.mer-text-white{
  color: white;
}

.mer-timeline-dialog>.MuiDialog-container>.MuiPaper-root {
  height: 100%;
  max-height: calc(100% - 134px);
  margin: 0px;
  width: 35%;
}

.mer-timeline-dialog>.MuiDialog-container {
  justify-content: end;
  align-items: end;
}

.mer-timeline-dialog-mobile>.MuiDialog-container>.MuiPaper-root {
  height: 100%;
  max-height: calc(100% - 134px);
  margin: 0px;
  width: 100%;
}

.mer-timeline-dialog-mobile>.MuiDialog-container {
  justify-content: end;
  align-items: end;
}

.sourced-profile-view-dialog>.MuiDialog-container>.MuiPaper-root {
  width: 80vw;
  min-width: 80vw;
  height: 80vh;
  min-height: 80vh;
  margin: 0px;
}

.change-password-dialog>.MuiDialog-container>.MuiPaper-root {
  width: 30vw;
  min-width: 30vw;
}

.request-documents-dialog>.MuiDialog-container>.MuiPaper-root {
  width: 65vw;
  min-width: 65vw;
  margin: 0px;
  min-height: 90vh;
  height: 90vh;
}

.sync-data-to-employeedb-dialog>.MuiDialog-container>.MuiPaper-root {
  width: 40vw;
  min-width: 40vw;
  margin: 0px;
  min-height: 45vh;
  height: 45vh;
}

.offer-letter-dialog>.MuiDialog-container>.MuiPaper-root {
  width: 84vw;
  min-width: 84vw;
  margin: 0px;
  min-height: 90vh;
  height: 90vh;
}

.previous-profile-view-dialog>.MuiDialog-container>.MuiPaper-root {
  max-width: 60vw;
  min-width: 60vw;
  height: 80vh;
  min-height: 80vh;
  margin: 0px;
}

.offer-candidate-comments>.MuiDialog-container>.MuiPaper-root {
  max-width: 50vw;
  min-width: 400px;
  margin: 0px;
}

.moving-profile-dialog>.MuiDialog-container>.MuiPaper-root {
  max-width: 40vw;
  min-width: 40vw;
}


.dialog-header-bg {
  background: $mer-color1;
  // color: $mer-color2;
}

.mer-theme-auto-specialIconalign {
  div>div>div {
    top: 2px !important;

    button {
      margin-top: 1px !important;
      top: 1px !important;
    }
  }
}

.mer-track-status-dialog>.MuiDialog-container>.MuiPaper-root {
  width: 70vw;
  min-width: 70vw;
  height: 70vh;
  min-height: 70vh;
  margin: 0px;
}

.mer-role-to-considered-dialog>.MuiDialog-container>.MuiPaper-root {
  width: 60vw;
  min-width: 60vw;
  height: 65vh;
  min-height: 65vh;
  margin: 0px;
}

.confirm-action-dialog-box>.MuiDialog-container>.MuiPaper-root {
  min-width: 30vw;
}

.mer-track-status-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogContent-root {
  padding: 0px;
}

.mer-success-dialog>.MuiDialog-container>.MuiPaper-root {
  // background: #CEF14F;
  background: $acc-lavender;
  border-radius: 16px;
}

.mer-success-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogContent-root>.MuiDialogContentText-root {
  color: black;
  font-weight: lighter;
}

.mer-success-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogActions-root>.MuiButton-root {
  background: $acc-content-primary;
  color:#fff;
}

.mer-error-dialog>.MuiDialog-container>.MuiPaper-root {
  background: #FFFFFF;
  border-radius: 16px;
}

.mer-error-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogTitle-root {
  color: #d32f2f;
}

.mer-error-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogContent-root>.MuiDialogContentText-root {
  color: #d32f2f;
  font-weight: lighter;
}

.mer-error-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogActions-root>.MuiButton-root {
  background: #d32f2f;
  color: white !important;
}

.mer-access-dialog>.MuiDialog-container>.MuiPaper-root {
  background: #E4E1EC;
  border-radius: 16px;
}

.mer-access-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogTitle-root {
  color: black;
}

.mer-access-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogContent-root>.MuiDialogContentText-root {
  color: black;
  font-weight: lighter;
}

.mer-filter-desktop>.MuiPaper-root {
  max-width: 40% !important;
  min-width: 40% !important;
}

.mer-filter-tablet>.MuiPaper-root {
  max-width: 65% !important;
  min-width: 65% !important;
}

.mer-filter-mobile>.MuiPaper-root {
  max-width: 94% !important;
  min-width: 94% !important;
}

.mer-upload-dialog {
  height: 60%;
  width: 60%;
}


.tablesearch {
  .MuiIconButton-root {
    padding: 0px;
  }
}


// .MuiTableCell-root {
//   padding: 0 !important;
// }

.mer-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mer-width-max-content {
  width: max-content;
}

.mer-cursor-block {
  cursor: no-drop;
}

.MuiClockPicker-arrowSwitcher {
  top: 8px !important;
  right: 4px !important;
}

// We overrided MuiYearPicker styles bcoz it was not working when we were using it in app.js
// .MuiYearPicker-root {
//   .PrivatePickersYear-root {
//     .Mui-selected {
//       color: white;
//     }

//     :hover {
//       color: White;
//       background-color: #5655a8
//     }
//   }
// }

.mer-form {
  .MuiAutocomplete-input {
    height: 18px !important;
  }


}

.MuiAutocomplete-endAdornment {
  right: 4px !important;
  top: 0 !important;
  transform: none !important;
}

// .MuiClockPicker-root {
//   // .MuiIconButton-root{
//   //   color: #ffffff !important;
//   // }

//   .Mui-selected {
//     color: #ffffff !important;
//   }
// }

.mer-text-wrapping {
  width: 95%;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.mer-break-word {
  word-wrap: anywhere;
}



input::-webkit-outer-spin-button,

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
}

.regenerate {
  color: #5655A8
}

.acknowledgementName {
  outline: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid black;
}

fieldset>legend>span {
  letter-spacing: 0px !important;
}

.MuiChip-root.active .MuiChip-avatar , .MuiChip-root.active .MuiChip-icon, .table-header-checkbox{
  color: #FFFFFF !important; /* Icon/avatar color when active */
}