@import '../variables.scss';

#dark {
  .global-search-container {
    position: relative !important;

    .MuiIconButton-root {
      padding: 0 !important;
    }
  }

  .global-search-items {
    position: absolute !important;
    top: 35px !important;
    padding: 8px !important;
    background: #000000;
    border-radius: 6px;
    color: white;
    min-width: 260x;
    max-height: 316px;
    overflow-y: auto;

    .global-search-item {
      border-bottom: 1px solid $mer-color2;
      color: $mer-color2;

      .name {
        color: white;
        font-weight: bold;
      }
    }
  }
  .mer-tabshadowmobile{
    box-shadow: 0px 2px 4px -1px rgba(230, 226, 226, 0.2), 0px 4px 5px 0px rgba(183, 173, 173, 0.14), 0px 1px 10px 0px rgba(134, 133, 133, 0.12);
   }
  .light-grayclr {
    color: #c8c8c8;
  }


  @for $i from -100 to 450 {

    .mer-styletheme-table-sticky-header-right-#{$i} {
      position: sticky;
      right: $i + px !important;
      z-index: 101 !important;
      background: #382e52 !important;
    }

    .mer-styletheme-table-sticky-header-left-#{$i} {
      position: sticky;
      left: $i + px !important;
      z-index: 101 !important;
      background: #382e52 !important;
    }

    .mer-styletheme-table-sticky-body-right-#{$i} {
      position: sticky;
      right: $i + px !important;
      z-index: 100 !important;
      background: #000000 !important;
    }

    .mer-styletheme-table-sticky-body-left-#{$i} {
      position: sticky;
      left: $i + px !important;
      z-index: 100 !important;
      background: #000000 !important;

    }
  }

  .mer-icon-color {
    color: white;
  }

  .mer-color2 {
    color: white;
  }

  .mer-color6 {
    color: white
  }

  .sourced-profile-name-highlight {
    color: white;
    // font-weight: bold;
  }

  .sourced-profile-name-highlight:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .referral-profile-name-highlight {
    color: white;
    // font-weight: bold;
  }

  .mer-tab-color-selected {
    color: #5655a8;
    font-weight: 700;
    opacity: 1
  }

  .mer-tab-color-unselected {
    color: #5252b5;
    font-weight: 600;
    opacity: 1
  }

   .mer-autoComplete {
    .MuiButtonBase-root {
       background-color: #1c1b1f !important;
    }
  }
  .mer-autoComplete > .MuiFormControl-root > .MuiOutlinedInput-root > .MuiChip-root {
    background-color: rgb(117, 117, 117) !important;
  }

  .mer-theme-text-area-bg {
    background-color: #292929;
    color: white;
  }

  .mer-access-denied-button{
    background-color: #410002;
    border-radius: 10px;
  }
  .mer-textareapadding>div{
  padding:6px 2px 2px 6px !important ;
  }
  .mer-light-purple-background {
    background-color: #3a393e;;
  }
}